@import "../../css/colors.scss";

#searchBar:focus {
    outline: none;
    box-shadow: 0 0 0 2pt $primary;
}

#searchBar {
    border-radius: 10px;
    overflow: hidden;
    height: 3rem;
    width: 80%;
    border: none;
    color: black;
    max-width: 400px;
    text-align: center;
    overflow: hidden;
}

#searchBar .form-control {
    text-align: center;
    // padding-left: 3rem;
    height: 100%;
}

#searchBar .form-control-feedback {
    position: absolute;
    z-index: 2;
    border-radius: 10px;
    background-color: white;
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 3rem;
    text-align: center;
    pointer-events: none;
    color: $light;
}
