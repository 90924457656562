@import "../../../css/colors.scss";

#contactRoot {
    background-color: $secondary;
    min-height: 130vh;
    padding-top: 100px;
}

#contactRoot p {
    color: $light;
    font-style: italic;
}

#contactUsContainer {
    color: white;
    max-width: 600px;
}

.form-input {
    text-align: left;
    color: $grey;
}

#btnSend {
    border-radius: 10px;
    border: 1px solid $primary;
    font-weight: bold;
    color: $primary;
    width: calc(100% - 4rem);
    margin-left: 2rem;
    margin-right: 2rem;
}
#btnSend:hover {
    color: white;
    background-color: $primary;
}

.errorMsg {
    color: $danger;
    font-size: 0.8rem;
    text-align: right;
    padding-left: auto;
    right: 20px;
    position: absolute;
}

.form-control.withError {
    border: 1px solid $danger;
}

.form-input {
    color: white;
}

#contactInfo {
    color: white;
    border: 2px solid $light;
    border-radius: 10px;
    padding: 10px;
    // background-color: $white;
}

@media (max-width: 767px) {
    .form-input {
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .errorMsg {
        right: 50px;
    }

    #contactInfo {
        margin-left: 2rem;
        margin-right: 2rem;
        padding-top: 2rem;
        width: calc(100% - 4rem);
    }
}

@media (min-width: 767px) {
    #contactInfo {
        padding-top: 2.5rem;
        // height: 400px;
        right: 20px;
    }
}
