@import "../../../css/colors.scss";

@media only screen and (min-width: 992px) {
  .sidebar-mobile {
    display: none !important;
  }
}

.nav-mobile {
  position: fixed;
  bottom: 25px;
  width: 100%;
  background-color: gray;
  height: 50px;
  z-index: 9999;
}

.nav-items {
  position: absolute;
  top: -8px;
}

.change-commerce {
  position: fixed;
  top: 100px;
  z-index: 9999;
}
