.position-img {
  position: absolute;
  right: 0;
}

.button-select-pay {
  width: 75%;
  font-size: calc(1.375rem + 1.5vw);
  justify-content: center;
}
.img-pay {
  width: 6rem;
  height: 6rem;
}
@media only screen and (max-width: 600px) {
  .button-select-pay {
    width: 100%;
    font-size: 1.55rem;
    justify-content: left !important;
    padding-left: 2rem !important;
  }
  .img-pay {
    width: 4rem;
    height: 4rem;
  }
  .title-pay {
    font-size: 0.95rem;
  }
}
