@import "../../css/colors.scss";

#nav-profile {
  display: flex;
  justify-content: center;
}

#profile {
  display: flex;
  justify-content: center;
  padding: 0px;
}

#navbarPage {
  background-color: black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 1px 14px 0px rgba(0, 0, 0, 0.63%) !important;
}

#my-apps {
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  letter-spacing: normal !important;
  line-height: 1.2;
  padding: 5px;
  border-radius: 10px;
  border: 2px solid $primary;
  text-transform: uppercase;
}

#my-apps:hover {
  background-color: $primary;
  color: white !important;
}

#dropdownToggle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0px;
  margin-top: 10px;
  height: 100%;
  margin-right: 5px;
  color: $white;
}

#dropdownToggle:focus {
  outline: none;
  box-shadow: 0 0 0 2pt $primary;
}

#dropdownMenuProfile {
  padding: 10px;
  text-align: center;
}
#dropdownMenuProfile .nav-link.active,
#dropdownMenuProfile .nav-link {
  color: black;
  text-transform: uppercase;
}

@media (min-width: 991px) {
  #nav-profile {
    display: flex;
    flex-direction: row;
  }

  #dropdownMenuProfile {
    position: absolute;
    right: 10px;
    top: 50px;
    min-width: 300px;
  }

  #dropdownToggle {
    height: 50px;
    padding: 10px;
    margin-top: -10px;
  }

  #profile {
    position: relative;
  }
  #profile img {
    margin-bottom: -10px;
  }
}

@media (max-width: 991px) {
  #nav-profile {
    position: absolute;
    right: 55px;
    top: 10px;
  }

  #profile {
    top: -8px;
  }

  #profile .dropdown-menu {
    max-width: 400px;
    width: 200px;
    right: -50px;
    top: 60px;
    position: absolute;
  }

  #my-apps {
    margin-top: 10px !important;
    margin-right: 10px;
  }
}
.dropdown-menu[data-bs-popper] {
  left: -230px !important;
}
