@import "../../css/colors.scss";

.division:hover {
    cursor: pointer;
    background-color: $primary;
    border-radius: 10px;
}

.selectedAppsContainer {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    max-width: 600px;
    padding-right: 2px;
    margin: 0px;
    display: flex;
    flex-wrap: nowrap;
    height: 65px;
}

.selectedApp {
    color: white;
    background-color: $primary;
    height: 55px;
    width: 62px;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
}

.selectedApp.expanded {
    width: 90px;
}

.selectedAppText {
    width: 50px;
}

.selectedApp.expanded .selectedAppText {
    width: 50px;
}

.selectedAppRemove {
    width: 25px;
    // height: 100%;
}

.selectedAppRemove div {
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    border: none;
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20viewBox%3D%220%200%2050%2050%22%20width%3D%2250%22%20height%3D%2250%22%3E%3Cdefs%3E%3Cpath%20d%3D%22M50%2025C50%2038.8%2038.8%2050%2025%2050C11.2%2050%200%2038.8%200%2025C0%2011.2%2011.2%200%2025%200C38.8%200%2050%2011.2%2050%2025Z%22%20id%3D%22hnBKqBm2S%22%3E%3C%2Fpath%3E%3CclipPath%20id%3D%22clipa8AZmMfAct%22%3E%3Cuse%20xlink%3Ahref%3D%22%23hnBKqBm2S%22%20opacity%3D%221%22%3E%3C%2Fuse%3E%3C%2FclipPath%3E%3Cpath%20d%3D%22M28.57%2025L34.95%2032.05L31.38%2035.99L25%2028.95L18.62%2035.99L15.05%2032.05L21.43%2025L15.05%2017.95L18.62%2014.01L25%2021.05L31.38%2014.01L34.95%2017.95L28.57%2025Z%22%20id%3D%22a35GZAm3eG%22%3E%3C%2Fpath%3E%3C%2Fdefs%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cg%20clip-path%3D%22url%28%23clipa8AZmMfAct%29%22%3E%3Cuse%20xlink%3Ahref%3D%22%23hnBKqBm2S%22%20opacity%3D%221%22%20fill-opacity%3D%220%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%2210%22%20stroke-opacity%3D%221%22%3E%3C%2Fuse%3E%3C%2Fg%3E%3C%2Fg%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D%22%23a35GZAm3eG%22%20opacity%3D%221%22%20fill%3D%22%23ffffff%22%20fill-opacity%3D%221%22%3E%3C%2Fuse%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.react-calendar {
    width: 300px;
    margin: auto;
    background-color: $grey;
}

.react-date-picker__inputGroup input:first-child {
    width: 0px;
}

.react-calendar {
    border-radius: 10px;
}

.react-calendar abbr {
    border-bottom: none !important;
    color: $white;
    text-decoration: none !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
    color: $light;
}

.react-calendar__tile {
    height: 40px;
    font-weight: normal;
}

.react-calendar__tile:disabled {
    opacity: 0.1;
}
.react-calendar__tile:disabled abbr {
    // color: $light;
    font-weight: lighter;
}

.btnOpenCalendarContainer {
    background-color: $grey;
    border-radius: 10px;
    max-width: 200px;
}
.btnOpenCalendar {
    cursor: pointer;
}

.react-calendar__tile--now {
    background-color: transparent;
}
.react-calendar__tile--now abbr {
    color: $primary;
}

.react-calendar__tile--active {
    background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20version%3D%221.1%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cdefs%3E%3Cpath%20d%3D%22M20%2010C20%2015.52%2015.52%2020%2010%2020C4.48%2020%200%2015.52%200%2010C0%204.48%204.48%200%2010%200C15.52%200%2020%204.48%2020%2010Z%22%20id%3D%22a2P0bdYkpx%22%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D%22%23a2P0bdYkpx%22%20opacity%3D%221%22%20fill%3D%22%23ff8672%22%20fill-opacity%3D%221%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position: center;
}

.react-calendar__tile--active abbr {
    color: white;
}

.react-calendar__navigation__prev2-button {
    border-top-left-radius: 10px;
}

.react-calendar__navigation__arrow {
    width: 40px;
}

.react-calendar__navigation {
    height: 40px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: transparent;
}

.nearAppsContainer {
    max-width: 350px;
}

.dateNearAppContainer {
    height: 40px;
}
.dateName {
    font-size: 0.9rem;
}
.moreNearApp,
.nearApp {
    width: 65px;
    border: 1px solid $primary;
    border-radius: 10px;
    font-size: 1rem;
    color: $primary;
}

.nearApp:hover {
    background-color: $black;
}

.moreNearApp {
    width: 70px;
    font-size: 0.8rem;
    border: 1px solid $white;
}

.containerApps {
    max-width: 500px;
}

.selectedAppsLabel {
    font-size: 0.8rem;
    max-width: 600px;
}

.freeLabel {
    font-size: 0.8rem;
    max-width: 500px;
}

.nearAppSkeleton {
    width: 65px;
    height: 40px;
    border-radius: 10px;
    overflow: hidden;
}

.noApps {
    font-size: 0.8rem;
}

.labelNearApps {
    max-width: 350px;
    font-size: 0.8rem;
}

#credits-warning {
    background-color: $black;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    max-width: 600px;
    justify-content: center;
}

#credits-warning-span {
    text-align: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
}

#credits-warning .reached-minimum {
    font-weight: bold;
    color: $danger;
}

#credits-warning .icon {
    align-self: center;
    width: 40px;
    height: 20px;
    // margin-right: 10px;
    margin-left: 5px;
    background-repeat: no-repeat;
    background-size: 20px 20px;
}

#credits-warning .icon.danger {
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%23ff4952%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M22%2010V6c0-1.11-.9-2-2-2H4c-1.1%200-1.99.89-1.99%202v4c1.1%200%201.99.9%201.99%202s-.89%202-2%202v4c0%201.1.9%202%202%202h16c1.1%200%202-.9%202-2v-4c-1.1%200-2-.9-2-2s.9-2%202-2zm-2-1.46c-1.19.69-2%201.99-2%203.46s.81%202.77%202%203.46V18H4v-2.54c1.19-.69%202-1.99%202-3.46%200-1.48-.8-2.77-1.99-3.46L4%206h16v2.54zM11%2015h2v2h-2zm0-4h2v2h-2zm0-4h2v2h-2z%22%2F%3E%3C%2Fsvg%3E");
}

#credits-warning .icon.primary {
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%23ff8672%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M22%2010V6c0-1.11-.9-2-2-2H4c-1.1%200-1.99.89-1.99%202v4c1.1%200%201.99.9%201.99%202s-.89%202-2%202v4c0%201.1.9%202%202%202h16c1.1%200%202-.9%202-2v-4c-1.1%200-2-.9-2-2s.9-2%202-2zm-2-1.46c-1.19.69-2%201.99-2%203.46s.81%202.77%202%203.46V18H4v-2.54c1.19-.69%202-1.99%202-3.46%200-1.48-.8-2.77-1.99-3.46L4%206h16v2.54zM11%2015h2v2h-2zm0-4h2v2h-2zm0-4h2v2h-2z%22%2F%3E%3C%2Fsvg%3E");
}
#credits-warning .icon.success {
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%2381e443%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M22%2010V6c0-1.11-.9-2-2-2H4c-1.1%200-1.99.89-1.99%202v4c1.1%200%201.99.9%201.99%202s-.89%202-2%202v4c0%201.1.9%202%202%202h16c1.1%200%202-.9%202-2v-4c-1.1%200-2-.9-2-2s.9-2%202-2zm-2-1.46c-1.19.69-2%201.99-2%203.46s.81%202.77%202%203.46V18H4v-2.54c1.19-.69%202-1.99%202-3.46%200-1.48-.8-2.77-1.99-3.46L4%206h16v2.54zM11%2015h2v2h-2zm0-4h2v2h-2zm0-4h2v2h-2z%22%2F%3E%3C%2Fsvg%3E");
}

#credits-warning .btnContinue:hover #icon {
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%23fff%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M22%2010V6c0-1.11-.9-2-2-2H4c-1.1%200-1.99.89-1.99%202v4c1.1%200%201.99.9%201.99%202s-.89%202-2%202v4c0%201.1.9%202%202%202h16c1.1%200%202-.9%202-2v-4c-1.1%200-2-.9-2-2s.9-2%202-2zm-2-1.46c-1.19.69-2%201.99-2%203.46s.81%202.77%202%203.46V18H4v-2.54c1.19-.69%202-1.99%202-3.46%200-1.48-.8-2.77-1.99-3.46L4%206h16v2.54zM11%2015h2v2h-2zm0-4h2v2h-2zm0-4h2v2h-2z%22%2F%3E%3C%2Fsvg%3E");
}
