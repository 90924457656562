@import "../../../css/colors.scss";

.placeViewContainer {
    background-color: $black;
    padding-bottom: 120px;
}

.placeView {
    display: flex;
    justify-content: center;
    width: 100%;

    margin-left: auto;
    margin-right: auto;
    padding-top: 70px;
    flex-direction: column;
    background-color: $black;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    position: relative;
}

.placeImageWrapper {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

.placeImage {
    height: 150px;
    width: 150px;
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.placeImage img {
    border-radius: 100%;
    max-width: 200px;
    max-height: 200px;
    height: 150px;
    width: 150px;
}

.placeImageBackground {
    content: "";
    height: 166px;
    width: 166px;
    position: absolute;
    z-index: 0;
    border-radius: 100px;
    left: 0;
    right: 0;
    top: 0;
    transform: translate(-8px, -8px);
    border: 9px solid $primary;
}

.placeViewCollapsedContainer {
    background-color: $grey;
    padding-top: 25px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
}

.placeViewCollapsed {
    max-width: 500px;
    justify-content: center;
}

.placeImageCollapsed,
.placeImageCollapsed img {
    height: 50px;
    width: 50px;
    border-radius: 100%;
}

.btnWantMyAppointment {
    background-color: $primary;
    position: absolute;
    color: $white;
    border-radius: 100px;
    cursor: pointer;
    padding: 15px;
    width: 300px;
    font-size: 1rem;
    box-shadow: 0px 2px 20px 5px rgb(0, 0, 0);
    transform: translate(-50%, -50%);
}

.btnWantMyAppointment:hover,
.btnWantMyAppointment:active {
    color: $primary;
    background-color: $white;
}

.btnWantMyAppointment:active {
    color: $white;
    background-color: $primary;
    box-shadow: 0px 2px 5px 2px rgb(0, 0, 0);
}

.placeName,
.placeNameCollapsed {
    margin-top: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-wrap: nowrap;
    font-size: 1.7rem;
    font-weight: bold;
    color: white;
    justify-content: center;
    text-transform: uppercase;
}

.placeNameCollapsed {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 1.3rem;
    white-space: nowrap;
}

.placeContact {
    margin-top: 20px;
    font-size: 1.2rem;
}

.placeInfo {
    max-height: 100px;
    color: $light;
    margin-left: auto;
    margin-right: auto;
}

.placeInfoCollapsed {
    height: 20px;
    width: 20px;
}

.btnContinue {
    border-radius: 10px;
    border: 1px solid $primary;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 20px);
    max-width: 600px;
    cursor: pointer;
    opacity: 1;
    -webkit-transition: 0.2s -webkit-opacity linear;
    -moz-transition: 0.2s -moz-opacity linear;
    -moz-transition: 0.2s opacity linear;
    -ms-transition: 0.2s -ms-opacity linear;
    -o-transition: 0.2s -o-opacity linear;
    transition: 0.2s opacity linear, 0.2s -webkit-filter linear, border 0.2s linear, color 0.2s linear;
}

.btnContinue.disabled {
    color: $light;
    border: 1px solid $light;
    cursor: unset;
}

.btnContinue.hidden {
    opacity: 0;
    cursor: unset;
}

.btnContinue.active:hover {
    color: white;
    background-color: $primary;
}

.step.stepDone {
    color: $white;
    cursor: pointer;
}

.step.stepCurrent {
    color: $primary;
}

.step.stepFuture {
    color: $light;
}

.linkCalendar {
    width: 200px;
    border-radius: 10px;
    background-color: $primary;
    border: 1px solid $primary;
    padding: 10px;
    font-size: 0.8rem;
}

@media (min-width: 992px) {
    .placeViewCollapsedContainer {
        margin-top: 10px;
    }
}

.goToMapsWrapper {
    padding: 10px;
    border: 1px solid $primary;
    width: 200px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    color: $primary;
    border-radius: 10px;
}

.goToMapsWrapper:hover {
    background-color: $primary;
    color: white;
}

.goToMaps {
    height: 20px;
    width: 20px;
    background-image: url("data:image/svg+xml;utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Capa_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%22466.712px%22%20height%3D%22466.712px%22%20viewBox%3D%220%200%20466.712%20466.712%22%20style%3D%22enable-background%3Anew%200%200%20466.712%20466.712%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%09%3Cg%3E%0A%09%09%3Cg%3E%0A%09%09%09%3Cpath%20fill%3D%22%23ff8672%22%20d%3D%22M233.353%2C0C178.301%2C0%2C126.625%2C31.355%2C100.94%2C80.019c-13.302%2C25.216-19.077%2C54.085-16.639%2C82.496%0A%09%09%09c3.088%2C35.802%2C19.44%2C62.606%2C40.401%2C90.566c46.11%2C61.487%2C74.454%2C134.439%2C101.735%2C205.947c3.912%2C10.251%2C10.261%2C10.242%2C14.172-0.009%0A%09%09%09c26.718-69.94%2C54.478-140.856%2C98.59-201.74c18.724-25.848%2C35.773-49.209%2C41.491-81.578c5.336-30.236%2C1.187-61.879-11.914-89.658%0A%09%09%09C344.307%2C34.138%2C290.775%2C0%2C233.353%2C0z%20M233.353%2C194.157c-25.838%2C0-47.411-21.583-47.411-47.421%0A%09%09%09c0-25.847%2C21.573-47.42%2C47.411-47.42c25.848%2C0%2C47.431%2C21.573%2C47.431%2C47.42C280.783%2C172.574%2C259.2%2C194.157%2C233.353%2C194.157z%22%20%2F%3E%0A%09%09%3C%2Fg%3E%0A%09%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%3C%2Fsvg%3E");
    background-size: 20px 20px;
    background-repeat: no-repeat;
}

.goToMapsWrapper:hover .goToMaps {
    background-image: url("data:image/svg+xml;utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22iso-8859-1%22%3F%3E%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Capa_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20width%3D%22466.712px%22%20height%3D%22466.712px%22%20viewBox%3D%220%200%20466.712%20466.712%22%20style%3D%22enable-background%3Anew%200%200%20466.712%20466.712%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%09%3Cg%3E%0A%09%09%3Cg%3E%0A%09%09%09%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M233.353%2C0C178.301%2C0%2C126.625%2C31.355%2C100.94%2C80.019c-13.302%2C25.216-19.077%2C54.085-16.639%2C82.496%0A%09%09%09c3.088%2C35.802%2C19.44%2C62.606%2C40.401%2C90.566c46.11%2C61.487%2C74.454%2C134.439%2C101.735%2C205.947c3.912%2C10.251%2C10.261%2C10.242%2C14.172-0.009%0A%09%09%09c26.718-69.94%2C54.478-140.856%2C98.59-201.74c18.724-25.848%2C35.773-49.209%2C41.491-81.578c5.336-30.236%2C1.187-61.879-11.914-89.658%0A%09%09%09C344.307%2C34.138%2C290.775%2C0%2C233.353%2C0z%20M233.353%2C194.157c-25.838%2C0-47.411-21.583-47.411-47.421%0A%09%09%09c0-25.847%2C21.573-47.42%2C47.411-47.42c25.848%2C0%2C47.431%2C21.573%2C47.431%2C47.42C280.783%2C172.574%2C259.2%2C194.157%2C233.353%2C194.157z%22%20%2F%3E%0A%09%09%3C%2Fg%3E%0A%09%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%09%3Cg%3E%3C%2Fg%3E%0A%3C%2Fsvg%3E");
}

@media (min-width: 768px) {
    .placeView {
        // justify-content: center;
        display: flex;
        width: 500px;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .placeImageWrapper {
        margin-left: unset;
        margin-right: unset;
        height: 0px;
        width: 140px;
    }

    .placeImage {
        height: 140px;
        width: 140px;
        border-radius: 100%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        z-index: 1;
        overflow: hidden;
    }

    .placeImageBackground {
        content: "";
        height: 156px;
        width: 156px;
        position: absolute;
        z-index: 0;
        border-radius: 100px;
        left: 0;
        right: 0;
        top: 0;
        transform: translate(-8px, -8px);
        border: 9px solid $primary;
    }

    .placeImage img {
        border-radius: 100%;
        max-width: 200px;
        max-height: 200px;
        height: 140px;
        width: 140px;
    }

    .placeName {
        flex-grow: 1;
        font-size: 1.8rem;
        width: calc(100% - 140px);
    }

    .placeContact {
        margin-left: 130px;
        font-size: 1.1rem;
        width: 100%;
    }

    .placeSlogan {
        margin-left: 130px;
        font-size: 1.1rem;
    }

    .placeInfo {
        margin-left: 130px;
        font-size: 1.1rem;
        width: 100%;
    }

    .goToMapsContainer {
        width: 100%;
        margin-top: 100px;
    }
}

.btnMainBooking {
    color: $primary;
    margin-top: 20px;
    border: 1px solid $primary;
    padding: 10px;
    width: 200px;
    border-radius: 10px;
}

.btnMainBooking:hover {
    background-color: $primary;
    margin-top: 20px;
    border: 1px solid $primary;
    color: white;
    padding: 10px;
    width: 200px;
    border-radius: 10px;
}
