@import "../../../css/colors.scss";

#manage-models-links {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

#manage-models-links a {
    background-color: $grey;
    margin: 10px;
    text-transform: uppercase;
    border-radius: 10px;
    text-decoration: none;
    font-weight: bold;
    padding: 20px;
}

#manage-models-links a:hover {
    background-color: black;
    color: $primary;
}

#usersContainer .user {
    background-color: $grey;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
}

#usersContainer .user:hover {
    background-color: $white;
}

.wspCredits {
    background-color: $grey;
    border-radius: 10px;
    font-size: 0.8rem;
}

.userDetails button {
    font-size: 0.7rem;
}

.job {
    padding: 10px;
    margin: 8px;
    background-color: $grey;
    border-radius: 10px;
}

.userTrial {
    padding: 10px;
    margin: 8px;
    background-color: $success;
    border-radius: 10px;
}

.userTrial.noTrial {
    background-color: $grey;
}
