@import '../../../css/colors.scss';


@media only screen and (max-width: 992px) {
  .sidebar-desktop{
    display: none !important;
  }
}


.superimposed{
  position: relative;
  top: 5px;
  z-index: 2;
}

.circle-navitem{
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
}
.circle-navitem.active{
  transform: scale(1.1);
  background-color: $primary;
}

.vertical-line{
  min-width: 10px;
  height: 100%;
  background-color: #bdbdbd;
  left: 50%;
  transform: translate(-50%);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.z-index{
  z-index: 2;
}


.img-fluid-h{
  max-height: 100%;
  width: auto;
}