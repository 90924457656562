@import "../../../../css/colors.scss";

.dayCell {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  text-align: right;
  overflow: hidden;
  cursor: pointer;
  width: calc(100% / 7);
  height: 80px;
  .dayDate {
    display: block;
    text-align: right;
    padding: 0 3px;
    position: relative;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
  .inactiveDay {
    color: #424141;
  }
}

@media only screen and (max-width: 600px) {
  .dayCell {
    height: 50px;
  }
}

.selectedCell {
  border-radius: 10px;
  background-color: $grey;
}

.dayText {
  display: inline-block;
  position: relative;
  height: 15px;
  width: 20px;
  line-height: 15px;
  font-size: 0.7em;
  text-align: center;
}

.currentDay {
  color: $primary;
}

.dayCellEventWrapper {
  display: block;
  text-align: left;
}

.dayEventsRemaining {
  position: absolute;
  font-size: 0.6em;
  height: 16px;
  width: 100%;
  line-height: 16px;
  bottom: 0;
  z-index: 10;
  left: 0;
}

.match-cite {
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;

  border-radius: 50%;
  background-color: $light;
  color: $dark;
  padding: 10px;
  font-size: 0.8rem;
}
