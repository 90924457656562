@import "../../../css/colors.scss";

#jobtype-container {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.jobtype {
    background-color: $grey;
    font-weight: bold;
    padding: 10px;
    border-radius: 10px;
    width: 150px;
    height: 100px;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    margin: 5px;
}

.jobtype:hover {
    background-color: $black;
    color: $primary;
    cursor: pointer;
}
