@import "../../../../css/colors.scss";

ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $primary;
}

.list-client {
  display: flex;
  gap: 5px;
  align-items: center;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.list-client.active {
  background-color: black;
}

.collapsed-clients {
  transition: all 0.5s ease;
}

.collapsed-clients.active {
  //   transition: all 1s ease;
  transform: rotate(-180deg);
}
