@import "../../../../css/colors.scss";

#manage-places form {
    display: flex;
    justify-content: center;
    align-content: flex-start;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
}

#manage-places form:first {
    flex-grow: 2;
}
#manage-places form:last-child {
    flex-grow: 1;
}
