@import "../../../css/colors.scss";

#containerStats .container {
    background-color: $grey;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

#containerStats #jobTypes span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
