.RSPBprogressBar {
    width: 75%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 40px;
}

.RSPBprogressBar .RSPBstep {
    top: 10px;
}
