@import "../../../css/colors.scss";

#messagesContainer {
}

#messagesContainer .messageRow {
    background-color: $grey;
    border-radius: 10px;
    padding: 10px;
    overflow: hidden;
}

#messagesContainer .messageRow span {
    font-weight: bold;
    color: $light;
    text-transform: uppercase;
}
#messagesContainer #dropdown-basic::after {
    content: none;
}
