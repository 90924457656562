@import "../../../css/colors.scss";

.calendarWrapper {
	width: 100%;
	margin-left: 30px;
	margin-right: 30px;
	max-width: 1300px;
}

@media (min-width: 440px) {
	#monthlyWrapper {
		min-width: 400px;
		max-width: 600px;
	}
}

.monthName {
	overflow: hidden;
	text-overflow: ellipsis;
}
.title {
	max-width: 200px;
}

.calendarHeader {
	border-bottom: 1px solid #e9e9e9;
	.thickText {
		font-size: 1.3rem;
		font-weight: 700;
	}
	.thinText {
		font-size: 1.3rem;
		font-weight: 200;
	}
}

button {
	background: none;
	color: $primary;
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
	font-size: 0.8em;
	font-weight: 700;
}

.headerBtnText {
	overflow: hidden;
	text-overflow: ellipsis;
}

.calendarHeaderButtons img {
	width: 10px;
	height: 10px;
	margin: 2px;
}

#btnPrev img {
	transform: rotateY(180deg);
}

.modeWrapper {
	text-align: center;
}

.modeButton {
	padding: 10px 20px;
	color: $white;
	transition-duration: 0.3s;
}

.modeButtonActive {
	color: $primary;
}
