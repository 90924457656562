@import "../../../../css/colors.scss";

#dailyWrapper {
    min-width: 300px;
    max-width: 600px;
}

.dailyEvent {
    font-size: 0.8rem;
    color: white;
    height: 30px;
    margin: 5px;
    padding: 5px;
    border-radius: 10px;
    background-color: $primary;
    cursor: pointer;
}

.dailyEvent.past {
    color: $black;
    background-color: $light;
}

@media (min-width: 617px) {
    #dailyWrapper {
        padding-top: 15px;
    }
}
