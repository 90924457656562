@import "../../css/colors.scss";

.employee-preview img {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    overflow: hidden;
}
.preview {
}

.containerPreview {
    background-color: $grey;
    border-radius: 10px;
    width: 400px;
    overflow: hidden;
}

.previewServices {
    max-width: 400px;
    margin-left: 0px;
    margin-right: 0px;
    background-color: $grey;
}

#btnConfirmAppointments {
    border-radius: 10px;
    width: 140px;
    height: 50px;
    border: 1px solid $primary;
    padding: 15px;
}

#btnConfirmAppointments.booking {
    border: 1px solid $light;
    color: $light;
}

.recaptchaContainer {
    position: relative;
}

.recaptcha {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 99;
}

#loadingCaptcha {
    position: absolute;
    top: 28px;
    right: 0;
    left: 0;
    z-index: 1;
}

.progress {
    margin-left: 8px;
    margin-right: 8px;
    padding: 0px;
    height: 2px;
    background-color: transparent;
    position: relative;
}

.progress-bar {
    position: absolute;
    height: 2px;
    width: 25%;
    border-radius: 5px;
    background-color: $primary;
    animation: indeterminate 1s linear 0s infinite;
}

@keyframes indeterminate {
    0% {
        left: -25%;
        width: 25%;
    }

    100% {
        left: 100%;
    }
}

.recaptcha {
    max-width: 300px;
}
