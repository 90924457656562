@import '../../../../css/colors.scss';

.select{
    border: 1px solid $black;
    background-color: transparent;
    border-radius: 5px;
    padding: 5px 20px;
    color: $black;
    font-weight: 600;
}

.active-hour{
    border-color: $primary !important;
    color: $primary !important;
}