@import '../../../../css/colors.scss';

.input-search{
    background-color: $grey-light;
    border: none;
    color: $light;
    text-align: center;
    border-top-left-radius: 5px ;
    border-bottom-left-radius: 5px ;
}

.input-search:focus-visible{
    box-shadow: none;
    border: none;
}

.search-icon{
    position: absolute;
    top: 49%;
    left: 15px;
    transform: translateY(-50%);
}