@import "../../css/colors.scss";

.my-commerce-root {
  height: 100vh;
  padding-top: 120px;
}
// .active-desktop {
//   display: block !important;
// }
// .active-mobile {
//   display: none !important;
// }
// .show-info-mobile {
//   display: none;
// }
// @media only screen and (max-width: 1200px) {
//   .active-desktop {
//     display: none !important;
//   }
//   .show-info-mobile {
//     display: block;
//   }
// }
// @media only screen and (max-width: 1199px) {
//   .active-mobile {
//     display: block !important;
//   }
//   .show-info-mobile {
//     display: none !important;
//   }
// }

.message-mobile {
  display: none !important;
}

.message-desktop {
  display: none !important;
}
@media only screen and (min-width: 997px) {
  .message-desktop {
    display: block !important;
  }
}

@media only screen and (max-width: 999px) {
  .message-mobile {
    display: block !important;
  }
  .my-commerce-root {
    width: 100%;
    height: calc(100vh - 150px);
    padding-top: 0px;
  }
  .cites-table,
  .clients-table,
  .custom-body {
    max-height: auto !important;
  }
  .col-breakpoint {
    height: auto !important;
    min-height: auto !important;
    max-height: auto !important;
  }
}
.col-breakpoint {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}

.full-height {
  height: 100%;
  max-height: 100%;
}
.full-screen {
  height: 100vh;
}

.w-90 {
  width: 90%;
  max-width: 90%;
}
.h-90 {
  min-height: 90%;
}

.rounded-4 {
  border-radius: 10px;
}

.bg-gray {
  background-color: $grey;
}

.bg-gray-light {
  background-color: $grey-light;
}
.fs-7 {
  font-size: 0.8rem !important;
}
.btn-black {
  background-color: $black;
  color: white;
  border-color: $black !important;
}
.btn-black:hover {
  color: white;
}
.border-black {
  border-color: $black !important;
}
.circle-info-primary-sm {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.circle-info-primary {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: $primary;
}

.circle-info-white {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
}

.cursor-pointer {
  cursor: pointer;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
}

a {
  text-decoration: none;
  color: white;
}

.general-border-radius {
  border-radius: 10px !important;
}
.general-border-radius-bottom {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.general-border-radius-top {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem #000000cb !important;
}
