@import "../../../../css/colors.scss";
.custom-item {
  height: 100%;
  max-height: 100%;
}
.custom-head {
  margin: 0;
}

.accordion-button {
  border: 1px solid $light;
  background-color: transparent;
  border-radius: 5px;
  padding: 5px 20px;
  color: $light;
  font-weight: 600;
}
.accordion-button:not(.collapsed) {
  background-color: $primary;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: none;
  color: white;
}
.accordion-button:focus {
  border: 1px solid $light;

  box-shadow: none;
}

.custom-body {
  border-left: 1px solid $light;
  border-right: 1px solid $light;
  border-bottom: 1px solid $light;
  height: calc(100% - 32px);
  overflow: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.accordion-collapse.collapse.show {
  height: 100%;
}

.h-px {
  height: 270px;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}
