@import "../../css/colors.scss";

#credits-page {
    margin-top: 130px;
}

#credits-page #credits-container {
    max-width: 600px;
    padding: 20px;
}

#credits-page .credits {
    background-color: $grey;
    border-radius: 10px;
    position: relative;
    // height: 200px;
    margin-top: 10px;
    // display: flex;
    // justify-content: center;
}

#credits-page .credits:hover {
    box-shadow: 0px 0rem 1rem 2px rgba(0, 0, 0, 0.43) !important;
    cursor: pointer;
}

#credits-page .credits-place {
    justify-content: center;
    display: flex;
    flex-direction: column;
}

#credits-page .credits-place-image {
    width: 60px;
    min-width: 60px;
    height: 60px;
    overflow: hidden;
    min-height: 60px;
    border-radius: 50%;
}

#credits-page .credits-place-image img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
}

.credits .external-link {
    position: absolute;
    right: 20px;
    bottom: 20px;
    color: $light;
}

.current-credits-count-icon {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%23ff8672%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M22%2010V6c0-1.11-.9-2-2-2H4c-1.1%200-1.99.89-1.99%202v4c1.1%200%201.99.9%201.99%202s-.89%202-2%202v4c0%201.1.9%202%202%202h16c1.1%200%202-.9%202-2v-4c-1.1%200-2-.9-2-2s.9-2%202-2zm-2-1.46c-1.19.69-2%201.99-2%203.46s.81%202.77%202%203.46V18H4v-2.54c1.19-.69%202-1.99%202-3.46%200-1.48-.8-2.77-1.99-3.46L4%206h16v2.54zM11%2015h2v2h-2zm0-4h2v2h-2zm0-4h2v2h-2z%22%2F%3E%3C%2Fsvg%3E");
}

#credits-page .credits .services-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    // justify-content: center;
}

#credits-page .credits .service {
    height: unset;
    width: unset;
    white-space: nowrap;
    background-color: $secondary;
    margin-top: 10px;
    border-radius: 10px;
    margin-right: 10px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.credits .info {
    font-size: 0.8rem;
    color: $light;
}

.credits-services {
    max-width: 400px;
}
