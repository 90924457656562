@import "../../css/colors.scss";

#mainNav {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s linear, visibility 0.3s linear;
  min-height: 66px;
  min-width: 360px;
  background-color: #000;
  display: flex;
  justify-content: end;
}

#mainNav.visible {
  opacity: 1;
  visibility: visible;
}

#mainNav .navbar-toggler {
  padding: 0.175rem;
  font-size: 0.75rem;
  color: $white;
  text-transform: uppercase;
  border: 0;
  padding: 0px;
}

#mainNav .navbar-toggler:focus {
  box-shadow: none;
}

#mainNav .navbar-brand {
  color: $primary;
  font-weight: 700;
  letter-spacing: 0.0625em;
  text-transform: uppercase;
}

#mainNav .navbar-brand img {
  height: 1.5rem;
}
#mainNav .nav-link {
  font-size: 1rem;
  color: $white;
  margin-top: auto;
  margin-bottom: auto;
  letter-spacing: 0.1em;
}
#mainNav .nav-link.active,
#mainNav .nav-link:hover {
  color: $primary;
}

@media (min-width: 992px) {
  #mainNav .navbar-brand {
    font-size: 1.5em;
    transition: font-size 0.3s ease-in-out;
  }
  #mainNav {
    height: 82px;
  }

  #collapseNavMain {
    right: 240px;
    position: absolute;
  }

  #collapseNavMain.not-logged {
    position: static;
    right: 0px;
  }

  #mainNav .nav-link {
    margin-left: 20px;
    margin-right: 20px;
  }
}

#navbarLogo {
  position: absolute;
  z-index: 99999;
  height: 100px;
  top: 20%;
  left: 8vw;
}

#navbarLine {
  background-color: $primary;
  width: 100%;
  height: 8px;
  position: absolute;
  left: 0%;
  bottom: 0;
}

@media (max-width: 990px) {
  #mainNav {
    background-color: $black;
    box-shadow: 1px -3px 20px #000000;
    display: flex;
    justify-content: end;
  }

  #mainNav .container {
    margin-bottom: 7px;
  }

  #mainNav .container {
    padding: 0px;
  }

  #collapseNavMain.collapsing,
  #collapseNavMain.show {
    padding-top: 30px;
  }

  #navbarLogo {
    top: 30px;
    left: 8px;
    height: 58px;
    position: absolute;
    transition: height 0.1s linear, top 0.1s linear;
  }

  #navbarLogo.expanded {
    top: 15px;
    height: 40px;
  }

  #navbarLine {
    position: absolute;
    bottom: -8px;
    top: unset;
  }

  #mainNav .navbar-toggler {
    text-align: center;
    margin-right: 8px;
    position: absolute;
    width: 40px;
    right: 0;
    top: 15px;
    height: 40px;
  }

  #mainNav .navbar-toggler img {
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M0%200h24v24H0V0z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M3%2018h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z%22%2F%3E%3C%2Fsvg%3E");
  }
}
