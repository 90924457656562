@import "../../css/colors.scss";

.sliderVertical {
    position: relative;

    list-style-type: none;
    text-align: center;
}

.sliderVertical.length5 {
    animation: sliderVertical5 7s linear infinite;
}

.sliderVertical.length8 {
    animation: sliderVertical8 8s linear infinite;
}

.sliderVertical:hover {
    animation-play-state: paused;
}

@keyframes sliderVertical5 {
    0% {
        top: 0px;
    }
    50% {
        top: -4em;
    }
    55% {
        top: -4em;
    }
    70% {
        top: 0px;
    }
    100% {
        top: 0px;
    }
}
@keyframes sliderVertical8 {
    0% {
        top: 0px;
    }
    50% {
        top: -8em;
    }
    55% {
        top: -8em;
    }
    70% {
        top: 0px;
    }
    100% {
        top: 0px;
    }
}

.blurVertical .sliderVertical {
    margin: 0;
    padding: 0 1em;
    line-height: 1.5em;
}

.blurVertical:before,
.blurVertical::before,
.blurVertical:after,
.blurVertical::after {
    left: 0;
    z-index: 1;
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background-image: linear-gradient(180deg, $grey, rgba(255, 255, 255, 0));
}

.blurVertical:after,
.blurVertical::after {
    bottom: 0;
    transform: rotate(180deg);
}

.blurVertical:before,
.blurVertical::before {
    top: 0px;
}

.containerOverflow {
    margin-top: 5px;
    overflow: hidden;
    position: relative;
    height: 80%;
}

.ellipsis {
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.sliderHorizontal {
    position: relative;
    animation: sliderHorizontal 8s linear infinite;
    list-style-type: none;
    text-align: center;
}

.sliderHorizontal:hover {
    animation-play-state: paused;
}

// @keyframes sliderHorizontal5 {
//     0% {
//         left: 5px;
//     }
//     50% {
//         left: -4em;
//     }
//     55% {
//         left: -4em;
//     }
//     70% {
//         left: 5px;
//     }
//     100% {
//         left: 5px;
//     }
// }

@keyframes sliderHorizontal {
    0% {
        transform: translateX(1%);
    }
    50% {
        transform: translateX(-55%);
    }
    55% {
        transform: translateX(-55%);
    }
    70% {
        transform: translateX(1%);
    }
    100% {
        transform: translateX(1%);
    }
}

.blurHorizontal .sliderHorizontal {
    margin: 0;
}

.blurHorizontal:before,
.blurHorizontal::before,
.blurHorizontal:after,
.blurHorizontal::after {
    top: 0;
    z-index: 1;
    content: "";
    position: absolute;
    width: 4px;
    height: 100%;
    background-image: linear-gradient(90deg, $grey, rgba(255, 255, 255, 0));
}

.blurHorizontal:before,
.blurHorizontal::before {
    left: 0;
}

.blurHorizontal:after,
.blurHorizontal::after {
    right: 0px;
    transform: rotate(180deg);
}
