@import "../../css/colors.scss";

#calendarContainer {
    margin-top: 140px;
    color: white;
}

@media (max-width: 992px) {
    #calendarContainer {
        margin-top: 100px;
        color: white;
    }
}

.dayText {
    padding: 0px;
}

.dayEvent:hover {
    cursor: pointer;
}

.yearlyMonth {
    float: unset;
    padding: 5px;
}

.yearlyMonth .yearlyMonthName {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.modeButton {
    font-size: unset;
}

.calendarDayOfWeek div {
    padding: 0px;
}

.dayCellEvent {
    background-color: $primary;
}

.dayCellEvent.past {
    background-color: $light;
    color: $black;
}

.ReactModal__Overlay {
    z-index: 11;
}

.modalClockIcon {
    width: 20px;
    height: 20px;
}

.dailyEventWrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 0 5px 3px;
}

.btnCancelApp {
    width: 200px;
    max-width: 200px;
}

.icon {
    color: rgb(139, 139, 139);
    width: 25px;
}
