@import "../../css/colors.scss";

#loginContainer {
  background-color: $secondary;
  min-height: 100vh;
  height: 100%;
}

#mainPageLogo {
  position: absolute;
  right: 0;
  left: 0;
  top: 20%;
  height: 200px;
  width: 200px;
}

#googleBtn {
  border: 1px solid white;
  border-radius: 50px;
  height: 40px;
  width: 100%;
  max-width: 320px;
  bottom: 10%;
  position: absolute;
  color: white;
  left: calc(50% - 320px / 2);
}
.btn-google-login {
  bottom: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#googleBtn:hover,
#googleBtn:active {
  color: black;
  background-color: white;
}

#googleBtn:disabled {
  opacity: 0.2;
  // background-color: rgb(148, 148, 147);
}

.googleLogo {
  width: 25px;
  height: 25px;
}

#cookies-desktop {
  display: none;
}

#cookies-mobile {
  display: block;
}

@media (min-width: 480px) {
  #cookies-mobile {
    display: none;
  }

  #cookies-desktop {
    display: block;
  }
}
