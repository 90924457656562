@import "../../css/colors.scss";

.skeletonBox {
    height: 100%;
    width: 100%;
    background: $grey;
    background-image: linear-gradient(to right, $grey 0%, #494949 20%, $grey 40%, $grey 100%);
    background-repeat: no-repeat;
    background-size: 800px 100%;
    display: inline-block;
    position: relative;

    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;

    @keyframes placeholderShimmer {
        0% {
            background-position: -468px 0;
        }

        100% {
            background-position: 468px 0;
        }
    }
}
