@import "../../css/colors.scss";

#admin-route-container {
    max-width: 500px;
}

.admin-button {
    background-color: $grey;
    width: 80%;
    max-width: 300px;
    margin: 10px;
    height: 100px;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    text-decoration: none;
    padding: 10px;
}

.admin-button:hover {
    color: $primary;
    background-color: $black;
    box-shadow: 0px 0rem 1rem 2px rgba(0, 0, 0, 0.43) !important;
}
