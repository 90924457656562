@import '../../../../css/colors.scss';
.rounded-img{
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    border: 5px solid  $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    
}

.sm{
    width: 2rem;
    height: 2rem;
}
.md{
    width: 3rem;
    height: 3rem;
}
.lg{
    width: 4rem;
    height: 4rem;
}
.lg-md{
    width: 5rem;
    height: 5rem;
}
.xl{
    width: 6rem;
    height: 6rem;
}

.img-dekstop{
    position: relative;
    top: 4px
}