@import "../../../css/colors.scss";

#containerCategoryView {
    padding-top: 100px;
    min-height: 150vh;
    color: $white;
}

#containerPlaces {
    max-width: 500px;
}

#distance-slider-holder {
    width: 300px;
    // height: 80px;
    margin-top: 20px;
    margin-bottom: 40px;
    background-color: $grey;
    border-radius: 10px;
    padding: 10px 20px;
}

.rc-slider-handle {
    border: none;
    background-color: $primary;
}

.rc-slider-track {
    background-color: $primary;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: $primary;
    box-shadow: 0 0 0 5px #96dbfa;
}
.rc-slider-handle:focus {
    outline: none;
}
.rc-slider-handle-click-focused:focus {
    border-color: #96dbfa;
    box-shadow: unset;
}
.rc-slider-handle:hover {
    border-color: $primary;
}
.rc-slider-handle:active {
    border-color: $primary;
    box-shadow: 0 0 5px $primary;
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.place {
    height: 100px;
    border-radius: 10px;
    padding: 10px;
    background-color: $grey;
    cursor: pointer;
    text-decoration: none;
}

.place:hover {
    box-shadow: 1px 0rem 1rem 2px rgba(0, 0, 0, 0.43) !important;
    // background-color: $black;
}

.placeSkeletonImg,
.placeImg {
    width: 60px !important;
    height: 60px !important;
    border-radius: 50%;
    overflow: hidden;
}

.place img {
    width: 60px;
    border-radius: 50%;
    height: 60px;
}

.businessName {
    color: white;
    white-space: nowrap;
}

.appointmentToday {
    color: white;
    font-size: 0.8rem;
    position: relative;
}

.appointmentToday .available {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $success;
}

.placeSkeletonContainer {
    margin-top: 135px;
    max-width: 500px;
}

.placeSkeleton {
    height: 100px;
    border-radius: 10px;
    padding: 10px;
    background-color: $grey;
    text-decoration: none;
}

.placeSkeletonText {
    padding: 0 !important;
    margin-left: 20px;
    width: 40% !important;
    margin-top: 20px !important;
    border-radius: 5px;
    overflow: hidden;
    height: 20px;
}

#notFoundLabel {
    color: $light;
}

#containerPlaces #btnDisableLocation,
#containerPlaces #btnGoBack {
    text-decoration: none;
    border-radius: 10px;
    border: 1px solid $primary;
    padding: 10px;
    width: 200px;
    color: $primary;
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
}

#containerPlaces #btnDisableLocation:hover,
#containerPlaces #btnGoBack {
    background-color: $primary;
    color: $black;
}

#disableLocationIcon {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M0%200h24v24H0V0z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M20.94%2011c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06c-.98.11-1.91.38-2.77.78l1.53%201.53C10.46%205.13%2011.22%205%2012%205c3.87%200%207%203.13%207%207%200%20.79-.13%201.54-.37%202.24l1.53%201.53c.4-.86.67-1.79.78-2.77H23v-2h-2.06zM3%204.27l2.04%202.04C3.97%207.62%203.26%209.23%203.06%2011H1v2h2.06c.46%204.17%203.77%207.48%207.94%207.94V23h2v-2.06c1.77-.2%203.38-.91%204.69-1.98L19.73%2021l1.41-1.41L4.41%202.86%203%204.27zm13.27%2013.27C15.09%2018.45%2013.61%2019%2012%2019c-3.87%200-7-3.13-7-7%200-1.61.55-3.09%201.46-4.27l9.81%209.81z%22%20fill%3D%22%23ff8672%22%2F%3E%3C%2Fsvg%3E");
}

#labelLodaingLocationPlaces {
    position: absolute;
    bottom: 15%;
    width: 100%;
}

#imgLodaingLocationPlaces,
#imgNeedLocation {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: 40px 40px;
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%23000%22%3E%3Cpath%20d%3D%22M12%208c-2.21%200-4%201.79-4%204s1.79%204%204%204%204-1.79%204-4-1.79-4-4-4zm8.94%203c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83%203.52%203.52%206.83%203.06%2011H1v2h2.06c.46%204.17%203.77%207.48%207.94%207.94V23h2v-2.06c4.17-.46%207.48-3.77%207.94-7.94H23v-2h-2.06zM12%2019c-3.87%200-7-3.13-7-7s3.13-7%207-7%207%203.13%207%207-3.13%207-7%207z%22%20fill%3D%22%23ff8672%22%2F%3E%3C%2Fsvg%3E");
}

#btnExpandLocationIcon {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%23000%22%3E%3Cpath%20d%3D%22M12%208c-2.21%200-4%201.79-4%204s1.79%204%204%204%204-1.79%204-4-1.79-4-4-4zm8.94%203c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83%203.52%203.52%206.83%203.06%2011H1v2h2.06c.46%204.17%203.77%207.48%207.94%207.94V23h2v-2.06c4.17-.46%207.48-3.77%207.94-7.94H23v-2h-2.06zM12%2019c-3.87%200-7-3.13-7-7s3.13-7%207-7%207%203.13%207%207-3.13%207-7%207z%22%20fill%3D%22%23ff8672%22%2F%3E%3C%2Fsvg%3E");
}

@keyframes radar {
    0% {
        width: 75px;
        height: 75px;
        border: 40px solid #fff;
        opacity: 0;
    }
    50% {
        opacity: 0.1;
    }
    90% {
        width: 250px;
        height: 250px;
    }
    90%,
    100% {
        border: 2px solid #fff;
        opacity: 0;
    }
    100% {
        width: 250px;
        height: 250px;
    }
}

.radar,
#imgLodaingLocationPlaces {
    top: 60%;
    left: 50%;
    position: absolute;
}
#imgLodaingLocationPlaces {
    margin-left: -20px;
    margin-top: -20px;
}
.radar {
    border-radius: 50%;
    border: 10px solid #fff;
    width: 150px;
    height: 150px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-animation: radar 3s infinite;
    animation: radar 3s infinite;
    opacity: 0;
}
.radar#wave2 {
    border: 10px solid $primary;
    animation-delay: 1.3s;
}

#tooltipInfo {
    width: 20px;
    height: 20px;
    margin-left: auto;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%23fff%22%3E%3Cpath%20d%3D%22M0%200h24v24H0V0z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M11%207h2v2h-2zm0%204h2v6h-2zm1-9C6.48%202%202%206.48%202%2012s4.48%2010%2010%2010%2010-4.48%2010-10S17.52%202%2012%202zm0%2018c-4.41%200-8-3.59-8-8s3.59-8%208-8%208%203.59%208%208-3.59%208-8%208z%22%2F%3E%3C%2Fsvg%3E");
}
