$primary: #ff8672;
$secondary: #1f1f1f;
$success: #358b00;
$black: #000000;
$white: #ffffff;
$light: #bdbdbd;
$grey-light: #5e5e5e;
$grey: #323232;
$danger: #ff4952;
$dark: #1f1f1f;
