@import "../../css/colors.scss";
.serviceSkeletonContainer,
.servicesContainer {
    max-width: 600px;
}

.serviceSkeleton,
.service {
    border-radius: 10px;
    width: 100px;
    height: 130px;
    overflow: hidden;
}

.jobType {
    margin-top: 30px;
    max-width: 600px;
    text-transform: uppercase;
}

.service {
    cursor: pointer;
    background-color: $grey;
}

.service:hover {
    box-shadow: 0px 0rem 1rem 2px rgba(0, 0, 0, 0.43) !important;
}

.serviceSkeleton {
    background-color: rgb(68, 68, 68);
}

.serviceSkeletonImg {
    height: 30px;
    width: 30px;
    overflow: hidden;
    border-radius: 100%;
}

.serviceSkeletonText {
    width: 100px;
    height: 20px;
    border-radius: 5px;
    overflow: hidden;
}

.checkBoxService {
    align-self: center;
    height: 20px;
    width: 20px;
    background-size: 20px 20px;
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20viewBox%3D%220%200%2050%2050%22%20width%3D%2250%22%20height%3D%2250%22%3E%3Cdefs%3E%3Cpath%20d%3D%22M50%2025C50%2038.8%2038.8%2050%2025%2050C11.2%2050%200%2038.8%200%2025C0%2011.2%2011.2%200%2025%200C38.8%200%2050%2011.2%2050%2025Z%22%20id%3D%22dr2mLmoEK%22%3E%3C%2Fpath%3E%3CclipPath%20id%3D%22clipaX0ZiKHrt%22%3E%3Cuse%20xlink%3Ahref%3D%22%23dr2mLmoEK%22%20opacity%3D%221%22%3E%3C%2Fuse%3E%3C%2FclipPath%3E%3C%2Fdefs%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cg%20clip-path%3D%22url%28%23clipaX0ZiKHrt%29%22%3E%3Cuse%20xlink%3Ahref%3D%22%23dr2mLmoEK%22%20opacity%3D%221%22%20fill-opacity%3D%220%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%224%22%20stroke-opacity%3D%221%22%3E%3C%2Fuse%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.checkBoxService.serviceSelected {
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%20viewBox%3D%220%200%2050%2050%22%20width%3D%2250%22%20height%3D%2250%22%3E%3Cdefs%3E%3Cpath%20d%3D%22M50%2025C50%2038.8%2038.8%2050%2025%2050C11.2%2050%200%2038.8%200%2025C0%2011.2%2011.2%200%2025%200C38.8%200%2050%2011.2%2050%2025Z%22%20id%3D%22i3kNduHbe3%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M25.75%2032.29L25.75%2032.29L20.92%2038.02L11.98%2027.4L16.81%2021.67L20.92%2026.55L33.19%2011.98L38.02%2017.71L25.75%2032.29Z%22%20id%3D%22a2rzmt5cqd%22%3E%3C%2Fpath%3E%3C%2Fdefs%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D%22%23i3kNduHbe3%22%20opacity%3D%221%22%20fill%3D%22%23ff8672%22%20fill-opacity%3D%221%22%3E%3C%2Fuse%3E%3C%2Fg%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D%22%23a2rzmt5cqd%22%20opacity%3D%221%22%20fill%3D%22%23ffffff%22%20fill-opacity%3D%221%22%3E%3C%2Fuse%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

.serviceName {
    overflow: hidden;
    height: 55%;
}

.priceTag {
    width: 100%;
}

.priceTag div:first-child {
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%23000000%22%3E%0A%0A%20%20%20%20%3Cpath%20fill%3D%22%23bdbdbd%22%20d%3D%22M11.8%2010.9c-2.27-.59-3-1.2-3-2.15%200-1.09%201.01-1.85%202.7-1.85%201.78%200%202.44.85%202.5%202.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5%201.68-3.5%203.61%200%202.31%201.91%203.46%204.7%204.13%202.5.6%203%201.48%203%202.41%200%20.69-.49%201.79-2.7%201.79-2.06%200-2.87-.92-2.98-2.1h-2.2c.12%202.19%201.76%203.42%203.68%203.83V21h3v-2.15c1.95-.37%203.5-1.5%203.5-3.55%200-2.84-2.43-3.81-4.7-4.4z%22%20%2F%3E%0A%3C%2Fsvg%3E");
    background-size: 20px 20px;
    background-repeat: no-repeat;
}
.priceTag div:last-child {
    overflow: hidden;
    text-overflow: ellipsis;
}

.emergency {
    background-size: 20px 20px;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    float: left;
    margin: 4px;
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20enable-background%3D%22new%200%200%2024%2024%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%23BDBDBD%22%3E%3Crect%20fill%3D%22none%22%20height%3D%2224%22%20width%3D%2224%22%2F%3E%3Cpolygon%20points%3D%2220.79%2C9.23%2018.79%2C5.77%2014%2C8.54%2014%2C3%2010%2C3%2010%2C8.54%205.21%2C5.77%203.21%2C9.23%208%2C12%203.21%2C14.77%205.21%2C18.23%2010%2C15.46%2010%2C21%2014%2C21%2014%2C15.46%2018.79%2C18.23%2020.79%2C14.77%2016%2C12%22%2F%3E%3C%2Fsvg%3E");
}

.credits {
    font-size: 0.8rem;
    font-weight: bold;
    color: $primary;
    float: left;
    margin-top: 7px;
    width: 40px;
    height: 20px;
    text-align: start;
    margin-left: 10px;
    margin-right: 10px;
}

.credits::after {
    position: absolute;
    display: inline-block;
    content: "";
    width: 40px;
    margin-left: 5px;
    height: 20px;
    margin-top: -1px;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%23ff8672%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M22%2010V6c0-1.11-.9-2-2-2H4c-1.1%200-1.99.89-1.99%202v4c1.1%200%201.99.9%201.99%202s-.89%202-2%202v4c0%201.1.9%202%202%202h16c1.1%200%202-.9%202-2v-4c-1.1%200-2-.9-2-2s.9-2%202-2zm-2-1.46c-1.19.69-2%201.99-2%203.46s.81%202.77%202%203.46V18H4v-2.54c1.19-.69%202-1.99%202-3.46%200-1.48-.8-2.77-1.99-3.46L4%206h16v2.54zM11%2015h2v2h-2zm0-4h2v2h-2zm0-4h2v2h-2z%22%2F%3E%3C%2Fsvg%3E");
}

.creditsIcon {
    position: absolute;
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-left: 5px;
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%23ff8672%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M22%2010V6c0-1.11-.9-2-2-2H4c-1.1%200-1.99.89-1.99%202v4c1.1%200%201.99.9%201.99%202s-.89%202-2%202v4c0%201.1.9%202%202%202h16c1.1%200%202-.9%202-2v-4c-1.1%200-2-.9-2-2s.9-2%202-2zm-2-1.46c-1.19.69-2%201.99-2%203.46s.81%202.77%202%203.46V18H4v-2.54c1.19-.69%202-1.99%202-3.46%200-1.48-.8-2.77-1.99-3.46L4%206h16v2.54zM11%2015h2v2h-2zm0-4h2v2h-2zm0-4h2v2h-2z%22%2F%3E%3C%2Fsvg%3E");
}

.btnContinue:hover .creditsIcon {
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2224px%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2224px%22%20fill%3D%22%23fff%22%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22%2F%3E%3Cpath%20d%3D%22M22%2010V6c0-1.11-.9-2-2-2H4c-1.1%200-1.99.89-1.99%202v4c1.1%200%201.99.9%201.99%202s-.89%202-2%202v4c0%201.1.9%202%202%202h16c1.1%200%202-.9%202-2v-4c-1.1%200-2-.9-2-2s.9-2%202-2zm-2-1.46c-1.19.69-2%201.99-2%203.46s.81%202.77%202%203.46V18H4v-2.54c1.19-.69%202-1.99%202-3.46%200-1.48-.8-2.77-1.99-3.46L4%206h16v2.54zM11%2015h2v2h-2zm0-4h2v2h-2zm0-4h2v2h-2z%22%2F%3E%3C%2Fsvg%3E");
}

.btnContinue {
    border-radius: 10px;
    border: 1px solid $primary;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 20px);
    max-width: 600px;
    cursor: pointer;
    opacity: 1;
    -webkit-transition: 0.2s -webkit-opacity linear;
    -moz-transition: 0.2s -moz-opacity linear;
    -moz-transition: 0.2s opacity linear;
    -ms-transition: 0.2s -ms-opacity linear;
    -o-transition: 0.2s -o-opacity linear;
    transition: 0.2s opacity linear, 0.2s -webkit-filter linear, border 0.2s linear;
}

.btnContinue.disabled {
    color: $light;
    border: 1px solid $light;
    cursor: unset;
}

.btnContinue.hidden {
    opacity: 0;
    cursor: unset;
}

.btnContinue.active:hover {
    color: white;
    background-color: $primary;
}
